<template>
  <div class="centerPage">
    <!-- <div id="zh" class="map"></div> -->
    <div class="centerBottom">
      <div class="detail-list">
        <div
          class="detail-list-item"
          v-for="(item, index) in detailsList"
          :key="index"
        >
          <div class="detail-item-text">
            <h3>{{ item.title }}</h3>
            <span>{{ item.number }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right-bottom">
      <div class="feedback-box">
        <div
          class="feedback-box-item"
          v-for="(item, index) in feedback"
          :key="index"
        >
          <Feed :chart-id="'feed' + index" :chart-data="item"></Feed>
          <span class="dis-text">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="centerBot">
      <dv-borderBox12 class="left-top-borderBox12">
        <div class="left-top">
          <div class="module-title">
            <i class="iconfont">&#xe78f;</i>
            <span>残疾人学员画像</span>
          </div>

          <div style="display:flex;justify-content: space-around;height:16rem;">
            <div style="display:flex;width:50%">
              <div style="margin-left:20px">
                <img
                  :src="require(`@/assets/images/${peopleData.info.imge}.jpg`)"
                  alt=""
                  style="width:200px;height:250px"
                />
              </div>
              <div style="margin-left:30px;">
                <dv-border-box-8 style="padding:10px 15px;line-height:30px;">
                  <div>姓名：{{ peopleData.info.name }}</div>
                  <div>年龄：{{ peopleData.info.age }}</div>
                  <div>性别：{{ peopleData.info.genrad }}</div>
                  <div>残疾类型：{{ peopleData.info.type }}</div>
                  <div>残疾等级：{{ peopleData.info.level }}</div>
                  <div>指导老师：{{ peopleData.info.zhidao }}</div>
                  <!-- <div>标签：{{ peopleData.info.age }}</div> -->
                </dv-border-box-8>
              </div>
            </div>
            <div id="geti" style="width:40%;height：8.25rem"></div>
          </div>
          <div id="linepo" style="height:18rem;width:100%"></div>
        </div>
      </dv-borderBox12>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import "echarts/map/js/china"
import Feed from "./feed.vue"
export default {
  components: {
    Feed,
  },
  data() {
    return {
      feedback: [
        {
          title: "到站率",
          number: 85,
        },
        {
          title: "就业率",
          number: 100,
        },
        {
          title: "培训率",
          number: 98,
        },
      ],

      detailsList: [
        {
          title: "已服务人数",
          number: "102",
          unit: "人次",
        },
        {
          title: "残疾学员数",
          number: "14",
          unit: "人",
        },
        {
          title: "融爱工坊产品数",
          number: 23,
          unit: "件 ",
        },
        {
          title: "融创乐园生产数",
          number: 2267,
          unit: "条",
        },
      ],

      people: [
        {
          info: {
            imge: 1,
            age: 38,
            genrad: "女",
            level: "二级",
            zhidao: "舒建荣",
            type: "精神",
            name: "郭*燕",
            tag: "",
          },
          geti1: [15, 20, 23, 18, 21],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [8, 7, 8, 9, 8, 8.5, 7.5, 6, 4, 8, 7.5, 8, 6],
          ydata2: [
            150,
            140,
            152,
            163,
            148,
            149,
            139,
            113,
            99,
            123,
            137,
            156,
            110,
          ],
        },
        {
          info: {
            imge: 2,
            age: 45,
            genrad: "女",
            type: "精神",
            level: "二级",
            name: "*净",
            zhidao: "普雪萍",
            tag: "",
          },
          geti1: [16, 15, 18, 20, 23],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [8, 7, 6, 8, 8, 6, 7, 4, 9, 8, 7, 8, 9],
          ydata2: [
            140,
            130,
            122,
            143,
            118,
            129,
            109,
            123,
            99,
            113,
            137,
            116,
            100,
          ],
        },
        {
          info: {
            imge: 3,
            type: "精神",
            age: 35,
            genrad: "男",
            name: "*峦",
            level: "四级",
            zhidao: "舒建荣",
            tag: "",
          },
          geti1: [17, 22, 13, 19, 10],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [8, 7, 8, 8, 7, 6, 5, 6, 4, 7, 7, 7, 6],
          ydata2: [
            145,
            120,
            119,
            153,
            128,
            139,
            149,
            103,
            120,
            113,
            127,
            136,
            99,
          ],
        },
        {
          info: {
            imge: 4,
            age: 37,
            genrad: "男",
            level: "四级",
            name: "*彪",
            type: "智力",
            zhidao: "普雪萍",
            tag: "",
          },
          geti1: [20, 19, 25, 17, 9],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [7, 6, 6, 7, 4, 3, 7, 6, 7, 8, 7, 8, 6],
          ydata2: [
            120,
            110,
            112,
            60,
            50,
            110,
            130,
            113,
            119,
            123,
            115,
            124,
            117,
          ],
        },
        {
          info: {
            imge: 5,
            age: 31,
            genrad: "女",
            level: "二级",
            type: "智力",
            name: "*静",
            zhidao: "舒建荣",
            tag: "",
          },
          geti1: [10, 11, 12, 17, 15],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [9, 7, 8, 9, 8, 8.5, 7.5, 6, 4, 8, 7.5, 8, 6],
          ydata2: [
            150,
            140,
            152,
            163,
            148,
            149,
            139,
            113,
            99,
            123,
            137,
            156,
            110,
          ],
        },
        {
          info: {
            imge: 6,
            age: 30,
            genrad: "男",
            level: "二级",
            name: "*骋",
            type: "智力",
            zhidao: "舒建荣",
            tag: "",
          },
          geti1: [20, 10, 13, 24, 9],
          geti2: [10, 18, 17, 20, 19],

          xdata: [
            "11.20",
            "11.21",
            "11.23",
            "11.24",
            "11.25",
            "11.26",
            "11.27",
            "11.28",
            "11.29",
            "11.30",
            "12.1",
            "12.2",
            "12.3",
          ],
          ydata1: [7, 8, 8, 8, 8, 8, 7, 5, 4, 8, 7, 8, 6],
          ydata2: [
            110,
            120,
            130,
            143,
            110,
            120,
            130,
            113,
            109,
            112,
            117,
            126,
            110,
          ],
        },
      ],
      peopleData: {
        info: {
          imge: 1,
          age: 38,
          genrad: "女",
          level: "二级",
          name: "郭*燕",
          type: "精神",
          zhidao: "舒建荣",
          tag: "",
        },
        geti1: [15, 20, 23, 18, 21],
        geti2: [10, 18, 17, 20, 19],

        xdata: [
          "11.20",
          "11.21",
          "11.23",
          "11.24",
          "11.25",
          "11.26",
          "11.27",
          "11.28",
          "11.29",
          "11.30",
          "12.1",
          "12.2",
          "12.3",
        ],
        ydata1: [8, 7, 8, 9, 8, 8.5, 7.5, 6, 4, 8, 7.5, 8, 6],
        ydata2: [
          150,
          140,
          152,
          163,
          148,
          149,
          139,
          113,
          99,
          123,
          137,
          156,
          110,
        ],
      },
    }
  },
  mounted() {
    this.gettiOptions(this.peopleData)
    this.getlineOptions(this.peopleData)
    this.startInterval()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 10000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectRandom()
      }, time)
    },
    reSelectRandom() {
      var rand = Math.round(Math.random() * 5)
      // console.log(rand, "rand")
      this.$nextTick(() => {
        this.peopleData = this.people[rand]
        this.gettiOptions(this.people[rand])
        this.getlineOptions(this.people[rand])
      })
    },
    gettiOptions(peoledata) {
      if (document.getElementById("geti") == null) {
        return
      }
      var getichartDom
      var getiChart
      if (getichartDom == null && getiChart == null) {
        getichartDom = document.getElementById("geti")
        getiChart = echarts.init(getichartDom)
      }
      var legendData = ["个体数据", "平均数据"] //图例
      var indicator = [
        {
          text: "表达能力",
          max: 30,
        },
        {
          text: "配合状况",
          max: 30,
        },
        {
          text: "身体状况",
          max: 30,
        },
        {
          text: "手工能力",
          max: 30,
        },
        {
          text: "创新能力",
          max: 30,
        },
      ]
      var dataArr = [
        {
          value: peoledata.geti1,
          name: legendData[0],
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#4A99FF",
                // shadowColor: '#4A99FF',
                // shadowBlur: 10,
              },
              shadowColor: "#4A99FF",
              shadowBlur: 10,
            },
          },
          areaStyle: {
            normal: {
              // 单项区域填充样式
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: "#4A99FF",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(0,0,0,0)",
                  },
                  {
                    offset: 1,
                    color: "#4A99FF",
                  },
                ],
                globalCoord: false,
              },
              opacity: 1, // 区域透明度
            },
          },
        },
        {
          value: peoledata.geti2,
          name: legendData[1],
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#4BFFFC",
                // shadowColor: '#4BFFFC',
                // shadowBlur: 10,
              },
              shadowColor: "#4BFFFC",
              shadowBlur: 10,
            },
          },
          areaStyle: {
            normal: {
              // 单项区域填充样式
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: "#4BFFFC",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(0,0,0,0)",
                  },
                  {
                    offset: 1,
                    color: "#4BFFFC",
                  },
                ],
                globalCoord: false,
              },
              opacity: 1, // 区域透明度
            },
          },
        },
      ]
      var colorArr = ["#4A99FF", "#4BFFFC"] //颜色
      var option = {
        title: {
          show: false,
          text: "数学薄弱知识点分析",
          x: "4%",

          textStyle: {
            color: "#fff",
            fontSize: "22",
          },
          subtextStyle: {
            color: "#90979c",
            fontSize: "16",
          },
        },
        color: colorArr,
        legend: {
          orient: "vertical",
          icon: "circle", //图例形状
          data: legendData,
          top: -3,
          right: 4,
          itemWidth: 14, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 14, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 14,
            color: "#00E4FF",
          },
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "#153269",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#113865", // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 8,
            // symbol: 'angle',
            data: dataArr,
          },
        ],
      }
      getiChart.setOption(option)
      window.addEventListener("resize", function() {
        getiChart.resize() //下面可以接多个图
      })
    },
    getlineOptions(peoledata) {
      if (document.getElementById("linepo") == null) {
        return
      }
      var linepochartDom
      var linepoChart
      if (linepochartDom == null && linepoChart == null) {
        linepochartDom = document.getElementById("linepo")
        linepoChart = echarts.init(linepochartDom)
      }

      var charts = {
        unit: "",
        names: ["劳动产品出品量", "出勤时间"],
        lineX: peoledata.xdata,
        value: [peoledata.ydata2, peoledata.ydata1],
      }
      var color = ["rgba(205, 173, 62", "rgba(93, 255, 255"]
      var lineY = []

      for (var i = 0; i < charts.names.length; i++) {
        var x = i
        if (x > color.length - 1) {
          x = color.length - 1
        }
        var data = {
          name: charts.names[i],
          type: "line",
          color: color[x] + ")",
          yAxisIndex: i,
          smooth: false,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: color[x] + ", 0.3)",
                  },
                  {
                    offset: 0.8,
                    color: color[x] + ", 0)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          symbol: "circle",
          symbolSize: 5,
          data: charts.value[i],
        }
        lineY.push(data)
      }

      var option = {
        title: {},
        tooltip: {
          trigger: "axis",
        },

        legend: {
          show: false,
          top: "2%",
          data: charts.names,
          textStyle: {
            fontSize: 14,
            color: "F1F1F3",
          },
          right: "4%",
        },
        grid: {
          top: "10%",
          left: "4%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          data: charts.lineX,
          axisLabel: {
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
            // formatter: function(params) {
            //   return params.split(" ")[0] + "\n" + params.split(" ")[1]
            // },
          },
        },
        yAxis: [
          {
            show: true,
            splitArea: {
              show: true,
              areaStyle: {
                color: "rgba(24, 54, 138, 1)",
                // color: "rgba(1, 22, 53, 1)",
              },
            },
            name: "劳动产品出品量",
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "rgb(0,253,255,0.6)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgb(23,255,243,0.3)",
              },
            },

            axisLine: {
              show: true,
              lineStyle: {
                color: "rgb(0,253,255,0.6)",
              },
            },
          },
          {
            show: true,
            splitArea: {
              show: true,
              areaStyle: {
                color: "rgba(24, 54, 138, 1)",
                // color: "rgba(24, 54, 138, 1)",
              },
            },
            name: "出勤时间",
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "rgb(0,253,255,0.6)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgb(23,255,243,0.3)",
              },
            },

            axisLine: {
              show: true,
              lineStyle: {
                color: "rgb(0,253,255,0.6)",
              },
            },
          },
        ],
        series: lineY,
      }
      linepoChart.setOption(option)
      window.addEventListener("resize", function() {
        linepoChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.centerBot {
  position: relative;
  height: 38.75rem;
  width: 100%;
  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;
    .left-top {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
      .title-dis {
        margin-top: 0.9375;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;
        color: #c0c9d2;
        &-keyword {
          padding-left: 0.625rem;
          color: #47dae8;
        }
      }
    }
  }
}
.right-bottom {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .feedback-box {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 8.75rem;
      .dis-text {
        font-weight: bold;
        margin-top: 0.3125rem;
        color: #b2cfee;
        font-size: 1rem;
        background: linear-gradient(to bottom, #fff, #6176f4);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    }
  }
}
.centerPage {
  margin-top: 1.25rem;
  display: flex;
  widows: 100%;
  flex-direction: column;
  align-items: center;
}
.map {
  width: 53.125rem;
  height: 40.625rem;
}
.centerBottom {
  display: flex;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  // width: 100%;
  width: 53.125rem;
  height: 8.25rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      position: relative;
      height: 7.8125rem;
      padding: 0 0.625rem;
      justify-content: center;
      width: 24%;
      border-radius: 5px;
      border: 1px solid #343f4b;
      // background-color: rgba(19, 25, 47, 0.8);
      background-color: rgba(13, 28, 83, 0.3);
      img {
        width: 6.25rem;
        height: 6.25rem;
      }
      .detail-item-text {
        // margin-left: 0.625rem;
        // margin: 0 auto;
        text-align: center;
        h3 {
          color: #bcdcff;
          font-size: 16px;
          margin-bottom: 1.25rem;
        }
        span {
          font-weight: 500px;
          font-size: 1.25rem;
          font-weight: bolder;
          background: linear-gradient(to bottom, #fff, #4db6e5);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        .unit {
          font-size: 1rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
}
</style>
