<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="colorBlue fw-b fs-xl">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop mt-2"
            style="width:100%;height:40px"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <div style="text-align:start">
          <span>
            <icon name="chart-pie" class="text-icon"></icon>
          </span>
          <span class="fs-xl text mx-2">助残企业</span>
        </div>
        <!-- <dv-scroll-ranking-board
          class="dv-scr-rank-board mt-1"
          :config="ranking"
        /> -->
        <div class="swiper-pic">
          <swiper :options="swiperOption">
            <swiper-slide>
              <div class="lp-img">
                <img src="@/assets/logo/company1.png" alt="" />
                <img src="@/assets/logo/company2.png" alt="" />
                <img src="@/assets/logo/company3.png" alt="" />
                <img src="@/assets/logo/company4.png" alt="" />
                <img src="@/assets/logo/company5.png" alt="" />
                <img src="@/assets/logo/company6.png" alt="" />
                <img src="@/assets/logo/company7.png" alt="" />
                <img src="@/assets/logo/company8.png" alt="" />
              </div>
              <!-- style="width: 33.3%;cursor: pointer;" -->
            </swiper-slide>
            <swiper-slide>
              <div class="lp-img">
                <img src="@/assets/logo/company9.png" alt="" />
                <img src="@/assets/logo/company10.png" alt="" />
                <img src="@/assets/logo/company11.png" alt="" />
                <img src="@/assets/logo/company12.png" alt="" />
                <img src="@/assets/logo/company13.png" alt="" />
                <img src="@/assets/logo/company14.png" alt="" />
                <img src="@/assets/logo/company15.png" alt="" />
                <img src="@/assets/logo/company16.png" alt="" />
              </div>
              <!-- style="width: 33.3%;cursor: pointer;" -->
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- <div class="percent"> -->
      <!-- <div class="item bg-color-black">
          <span>今日任务通过率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>今日任务达标率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div> -->

      <!-- </div> -->
    </div>
  </div>
</template>

<script>
const formatter = (number) => {
  const numbers = number
    .toString()
    .split("")
    .reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""))

  return segs
    .join(",")
    .split("")
    .reverse()
    .join("")
}
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,

        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        loop: true,
      },
      titleItem: [
        {
          title: "赋能培训基地",
          number: {
            number: [362],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}个",
            style: {
              fontSize: 26,
            },
            formatter,
          },
        },
        {
          title: "培训人数",
          number: {
            number: [9466],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}人",
            style: {
              fontSize: 26,
            },
            formatter,
          },
        },
        {
          title: "培训合规率",
          number: {
            number: [97.23],
            toFixed: 2,
            textAlign: "center",
            content: "{nt}%",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "就业惠及城市",
          number: {
            number: [60],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}个",
            style: {
              fontSize: 26,
            },
            formatter,
          },
        },
        {
          title: "就业人数",
          number: {
            number: [8666],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}人",
            style: {
              fontSize: 26,
            },
            formatter,
          },
        },
        {
          title: "培训就业率",
          number: {
            number: [86.84],
            toFixed: 2,
            textAlign: "center",
            content: "{nt}%",
            style: {
              fontSize: 26,
            },
          },
        },
      ],
      ranking: {
        data: [
          {
            // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
            name: "北京",
            value: 210,
          },
          {
            name: "河北",
            value: 6,
          },
          {
            name: "海南",
            value: 1,
          },
          {
            name: "福建",
            value: 1,
          },
          {
            name: "广东",
            value: 1,
          },
          {
            name: "湖北",
            value: 5,
          },
          {
            name: "湖南",
            value: 1,
          },
          {
            name: "吉林",
            value: 1,
          },
          {
            name: "江苏",
            value: 4,
          },
          {
            name: "江西",
            value: 1,
          },
          {
            name: "辽宁",
            value: 1,
          },
          {
            name: "内蒙古",
            value: 1,
          },
          {
            name: "山东",
            value: 4,
          },
          {
            name: "山西",
            value: 1,
          },
          {
            name: "陕西",
            value: 1,
          },
          {
            name: "四川",
            value: 3,
          },
          {
            name: "云南",
            value: 1,
          },
          {
            name: "浙江",
            value: 11,
          },
          {
            name: "上海",
            value: 2,
          },
          {
            name: "天津",
            value: 15,
          },
          {
            name: "重庆",
            value: 1,
          },
        ],
        data1: [
          {
            name: "海淀区",
            value: 55,
          },
          {
            name: "朝阳区",
            value: 120,
          },
          {
            name: "东城区",
            value: 28,
          },
          {
            name: "西城区",
            value: 14,
          },
          {
            name: "丰台区",
            value: 18,
          },
          {
            name: "石景山区",
            value: 10,
          },
          {
            name: "门头沟区",
            value: 35,
          },
          {
            name: "房山区",
            value: 27,
          },
          {
            name: "通州区",
            value: 46,
          },
          {
            name: "顺义区",
            value: 30,
          },
          {
            name: "昌平区",
            value: 38,
          },
          {
            name: "大兴区",
            value: 15,
          },
          {
            name: "怀柔区",
            value: 55,
          },
          {
            name: "平谷区",
            value: 23,
          },
          {
            name: "密云区",
            value: 19,
          },
          {
            name: "延庆区",
            value: 20,
          },
        ],
        carousel: "single",
        unit: "家",
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3,
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5",
              },
            },
          },
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad",
              },
            },
          },
        },
      ],
    }
  },
  mounted() {
    // /**
    //  * 展示技能亮我风采
    //  */
    // new Swiper(".swiperele", {
    //   direction: "horizontal", // 垂直切换选项
    //   loop: true, // 循环模式选项
    //   slidesPerView: 3,
    //   spaceBetween: 20,
    //   speed: 3000, //匀速时间
    //   autoplay: {
    //     delay: 0,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: true,
    //   },
    // })
  },
  methods: {
    format(number) {
      // console.log(number, "number")
      const numbers = number
        .toString()
        .split("")
        .reverse()
      const segs = []

      while (numbers.length) segs.push(numbers.splice(0, 3).join(""))

      return segs
        .join(",")
        .split("")
        .reverse()
        .join("")
    },
  },
  components: {
    // CenterChart
  },
}
</script>

<style lang="scss" scoped>
$box-width: 600px;
$box-height: 450px;
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    justify-content: space-between;
    .bg-color-black {
      height: 281px;
      border-radius: 10px;
    }
    .text {
      color: #fff;
      text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
        0 0 40px #38cefe;
    }
    .ranking {
      padding: 10px;
      margin-top: 5px;
      width: 600px;
      .dv-scr-rank-board {
        height: 281px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}

.swiper-pic {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  // margin-top: -25px;
  .lp-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // margin-top: -10px;
    img {
      width: 24%;
      padding: 10px 0px;
      height: 100px;
    }
  }
}
</style>
