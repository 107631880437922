<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="height:2.5vh">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#568aea']" />
            <div class="title">
              <span
                v-if="isPageShow == 1"
                class="title-text"
                @click="pageClick()"
              >
                <img
                  src="@/assets/logo.png"
                  width="40px"
                  alt=""
                  style="margin-right:10px;"
                />
                国华众联"残疾人之家"SaaS平台大数据</span
              >
              <span
                v-if="isPageShow == 0"
                class="title-text"
                @click="pageClick()"
                ><img
                  src="@/assets/logo.png"
                  width="40px"
                  alt=""
                  style="margin-right:10px;"
                />国华众联残疾人培就一体大数据</span
              >
              <span
                v-if="isPageShow == 2"
                class="title-text"
                @click="pageClick()"
                ><img
                  src="@/assets/logo.png"
                  width="40px"
                  alt=""
                  style="margin-right:10px;"
                />国华众联市级职康站大数据</span
              >
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#568aea']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <!-- <el-carousel
          arrow="never"
          indicator-position="none"
          :autoplay="true"
          :loop="true"
          :interval="500000000000"
          height="97.5vh"
          style="width:100%"
        >
          <el-carousel-item> -->
        <!-- <swiper ref="mySwiper" :options="swiperOption"> -->
        <!-- <swiper-slide> -->
        <!-- 第二行 -->
        <div v-if="isPageShow == 0">
          <!-- <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width">
              <div style="display: flex;align-items: center;font-size: 24px;">
                <span class="react-before"></span>
                <span class="text">北京市助残大数据分析</span>
              </div>
              <div class="react-right ml-3">
                <span class="text">数据分析2</span>
              </div>
            </div>
            <div class="d-flex aside-width">
              <div class="react-right  mr-3"></div>
              <div
                style="line-height: 0px;width: 100%;display: flex;justify-content: flex-end;"
              >
                <img
                  class="text"
                  style="height:70px"
                  src="@/assets/logo.jpg"
                  alt=""
                />
                <span class="react-after"></span>
                <span class="text"
                  >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
                >
              </div>
            </div>
          </div> -->

          <div class="body-box">
            <!-- 第三行数据 -->
            <div class="content-box">
              <div>
                <dv-border-box-12>
                  <centerLeft1 />
                </dv-border-box-12>
              </div>
              <div>
                <dv-border-box-12>
                  <centerLeft2 />
                </dv-border-box-12>
              </div>
              <!-- 中间 -->
              <div>
                <center />
              </div>
              <!-- 中间 -->
              <div>
                <centerRight2 />
              </div>
              <div>
                <dv-border-box-13>
                  <centerRight1 />
                </dv-border-box-13>
              </div>
            </div>

            <!-- 第四行数据 -->
            <div class="bototm-box">
              <dv-border-box-13>
                <bottomLeft />
              </dv-border-box-13>
              
                <bottomRight />
              
            </div>
          </div>
        </div>
        <!-- </swiper-slide> -->
        <!-- </el-carousel-item> -->
        <!-- <el-carousel-item> -->
        <!-- <swiper-slide> -->
        <div v-if="isPageShow == 1" class="pagecontent">
          <LeftPage />
          <CenterPage className="center-page" />
          <RightPage />
        </div>

        <div v-if="isPageShow == 2" class="pagecontent">
          <zhiKangLeft />
          <zhiKangCenter className="center-page" />
          <zhiKangRight />
        </div>
        <!-- </el-carousel-item> -->
        <!-- </swiper-slide> -->
        <!-- </swiper> -->
        <!-- </el-carousel> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin"
import { formatTime } from "../utils/index.js"
import centerLeft1 from "./centerLeft1"
import centerLeft2 from "./centerLeft2"
import centerRight1 from "./centerRight1"
import centerRight2 from "./centerRight2"
import center from "./center"
import bottomLeft from "./bottomLeft"
import bottomRight from "./huai_bottomRight.vue"

import LeftPage from "../components/leftPage/index.vue"
import CenterPage from "../components/centerPage/index.vue"
import RightPage from "../components/rightPage/index.vue"

import zhiKangLeft from "../components/zhikang/left/index.vue"
import zhiKangCenter from "../components/zhikang/center/index.vue"
import zhiKangRight from "../components/zhikang/right/index.vue"
// import Swiper from "swiper"
// import "swiper/dist/css/swiper.min.css"

// import { Swiper, SwiperSlide } from "vue-awesome-swiper"
// import style (>= Swiper 6.x)
// import "swiper/swiper-bundle.css"
// import style (<= Swiper 5.x)
// import "swiper/css/swiper.css"
import "swiper/css/swiper.css"

export default {
  mixins: [drawMixin],
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 10000,
          // stopOnLastSlide: false,
          // disableOnInteraction: false,
        },
        // // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, //允许分页点击跳转
        // },
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      isPageShow: 0,
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
    LeftPage,
    CenterPage,
    RightPage,
    zhiKangLeft,
    zhiKangCenter,
    zhiKangRight,
    // Swiper,
    // SwiperSlide,
  },
  // directives: {
  //   swiper: directive,
  // },
  // computed: {
  //   swiperOne() {
  //     return this.$refs.mySwiper.swiper
  //   },
  // },
  mounted() {
    this.timeFn()
    // this.pageShow()
    this.cancelLoading()
    // console.log("Current Swiper instance object", this.swiperOne)
    // console.log("Current Swiper instance object", this.$refs.mySwiper)
    // console.log("Current Swiper instance object", this.$refs.mySwiper)
    // this.$nextTick(() => {
    //   let swiperTop = this.$refs.mySwiper
    //   console.log("Current Swiper instance object", swiperTop)
    // })
    // this.swiperOne.swiper.slideTo(3, 1000, false)
  },
  beforeDestroy() {
    // clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss")
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    pageClick() {
      if (this.isPageShow == 0) {
        this.isPageShow = 1
      } else if (this.isPageShow == 1) {
        this.isPageShow = 2
      } else if (this.isPageShow == 2) {
        this.isPageShow = 0
      }
    },
    pageShow() {
      // var rand = Math.round(Math.random() * 2)
      var rand = 0

      setInterval(() => {
        // if(rand==0){
        //   rand=0;
        // }
        // else if(rand==1){
        //   rand=0
        // }
        // else if(rand==2){
        //   rand=0
        // }
        this.isPageShow = rand
      }, 5000)
    },

    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
#app {
  background-color: #022165 !important;
  background-image: linear-gradient(#022165, #0252c3) !important;
}
.bg {
  background-image: linear-gradient(#022165, #0252c3) !important;
}
.bg-color-black {
    background-color: rgba(13, 28, 83, 0.3) !important;
}
.swiper-container {
  width: 100%;

  .swiper-slide {
    width: 100%;
  }
}
.indexPage {
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 10px 0 0 0;
}
.pagecontent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .center-page {
    flex: 1;
  }
}
// {
//    touch-action: none;
// }
</style>
