<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue"
export default {
  data() {
    return {
      cdata: {
        xData: ["融合培训", "技能培训", "非遗培训", "订单培训","素质教育","就业指导"],
        // xData:[],
        seriesData: [
          { value: 20, name: "融合培训" },
          { value: 30, name: "技能培训" },
          { value: 40, name: "非遗培训" },
          { value: 40, name: "订单培训" },
          { value: 50, name: "素质教育" },
          { value: 40, name: "就业指导" },
        ],
      },
    }
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
