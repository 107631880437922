<template>
  <div>
    <Chart v-if="!huairouMap" :cdata="cdata" @onclick="handlerClick()" />

    <!-- <HuaiRou
      v-if="huairouMap"
      height="440px"
      width="100%"
      :total="false"
      @onclick="handlerClick()"
    ></HuaiRou> -->
    <BeiJing v-if="huairouMap"></BeiJing>
  </div>
</template>

<script>
import Chart from "./chart.vue"
// import HuaiRou from "../../../../views/huairou/components/centerTop.vue"
import BeiJing from "../../../../views/component/home_beijing.vue"
export default {
  data() {
    return {
      huairouMap: false,
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: "北京",
          value: 210,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          },
        },
        {
          name: "河北",
          value: 6,
        },
        {
          name: "海南",
          value: 1,
        },
        {
          name: "福建",
          value: 1,
        },
        {
          name: "广东",
          value: 1,
        },
        {
          name: "湖北",
          value: 5,
        },
        {
          name: "湖南",
          value: 1,
        },
        {
          name: "吉林",
          value: 1,
        },
        {
          name: "江苏",
          value: 4,
        },
        {
          name: "江西",
          value: 1,
        },
        {
          name: "辽宁",
          value: 1,
        },
        {
          name: "内蒙古",
          value: 1,
        },
        {
          name: "山东",
          value: 4,
        },
        {
          name: "山西",
          value: 1,
        },
        {
          name: "陕西",
          value: 1,
        },
        {
          name: "四川",
          value: 3,
        },
        {
          name: "云南",
          value: 1,
        },
        {
          name: "浙江",
          value: 11,
        },
        {
          name: "上海",
          value: 2,
        },
        {
          name: "天津",
          value: 15,
        },
        {
          name: "重庆",
          value: 1,
        },

        // {
        //   name: '龙岩市',
        //   value: 3,
        // },
        // {
        //   name: '宁德市',
        //   value: 2,
        // }
      ],
    }
  },
  components: {
    Chart,
    BeiJing,
    // HuaiRou,
  },
  mounted() {},
  methods: {
    handlerClick() {
      if (this.huairouMap) {
        this.huairouMap = false
      } else {
        this.huairouMap = true
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
