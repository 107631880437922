<template>
  <div class="centerPage">
    <div id="bj" class="map"></div>
  </div>
</template>

<script>
import * as echarts from "echarts"
// import "echarts/map/js/china"
import "echarts/map/js/province/beijing.js"

export default {
  data() {
    return {
      points: [
        {
          name: "怀柔区宝山镇郑栅子村温馨家园",
          value: [116.361235, 40.913733],
        },
        {
          name: "怀柔区喇叭沟门满族乡喇叭沟门村温馨家园",
          value: [116.62064, 40.899371],
        },
        {
          name: "怀柔区长哨营满族乡八道河村温馨家园",
          value: [116.706176, 40.855728],
        },
        {
          name: "怀柔区长哨营满族乡七道河村温馨家园",
          value: [116.711649, 40.842708],
        },
        {
          name: "怀柔区长哨营满族乡二道河村温馨家园",
          value: [116.744216, 40.820487],
        },
        {
          name: "怀柔区宝山镇碾子村温馨家园",
          value: [116.491561, 40.809274],
        },
        {
          name: "怀柔区长哨营满族乡古洞沟村温馨家园",
          value: [116.795535, 40.797691],
        },
        {
          name: "怀柔区长哨营满族乡长哨营村温馨家园",
          value: [116.745836, 40.780182],
        },
        {
          name: "延庆区千家店镇平台子村温馨家园",
          value: [116.432853, 40.7535],
        },
        {
          name: "怀柔区宝山镇对石村温馨家园",
          value: [116.554479, 40.743878],
        },
        {
          name: "怀柔区宝山镇转年村温馨家园",
          value: [116.612986, 40.69669],
        },
        {
          name: "怀柔区宝山镇松树台村温馨家园",
          value: [116.569919, 40.694777],
        },
        {
          name: "延庆区千家店镇红旗店村温馨家园",
          value: [116.346835, 40.693859],
        },
        {
          name: "延庆区千家店镇温馨家园",
          value: [116.346835, 40.693859],
        },
        {
          name: "密云区古北口镇示范温馨家园",
          value: [117.16382, 40.692169],
        },
        {
          name: "密云区古北口镇河西村温馨家园",
          value: [117.152284, 40.688311],
        },
        {
          name: "密云区新城子镇东沟村温馨家园",
          value: [117.399792, 40.667652],
        },
        {
          name: "密云区新城子镇大角峪村温馨家园",
          value: [117.446298, 40.664971],
        },
        {
          name: "密云区古北口镇北甸子村温馨家园",
          value: [117.180909, 40.660041],
        },
        {
          name: "密云区新城子镇小口村温馨家园",
          value: [117.346842, 40.649845],
        },
        {
          name: "密云区新城子镇曹家路村温馨家园",
          value: [117.425648, 40.649784],
        },
        {
          name: "密云区新城子镇示范温馨家园",
          value: [117.334715, 40.644042],
        },
        {
          name: "密云区高岭镇下河村温馨家园",
          value: [117.093135, 40.640095],
        },
        {
          name: "怀柔区琉璃庙镇碾子湾村温馨家园",
          value: [116.615946, 40.630714],
        },
        {
          name: "密云区新城子镇太古石村温馨家园",
          value: [117.32762, 40.62489],
        },
        {
          name: "密云区冯家峪镇示范温馨家园",
          value: [116.875789, 40.620216],
        },
        {
          name: "密云区高岭镇示范温馨家园",
          value: [117.111067, 40.604234],
        },
        {
          name: "密云区石城镇西湾子村温馨家园",
          value: [116.846341, 40.60312],
        },
        {
          name: "密云区高岭镇高岭屯村温馨家园",
          value: [117.103228, 40.602507],
        },
        {
          name: "延庆区刘斌堡乡营盘村温馨家园",
          value: [116.27183, 40.601136],
        },
        {
          name: "密云区高岭镇瑶亭村温馨家园",
          value: [117.093222, 40.593942],
        },
        {
          name: "怀柔区琉璃庙镇崎峰茶村温馨家园",
          value: [116.606117, 40.585547],
        },
        {
          name: "密云区太师屯镇车道峪村温馨家园",
          value: [117.202326, 40.580828],
        },
        {
          name: "密云区太师屯镇小漕村温馨家园",
          value: [117.113027, 40.579617],
        },
        {
          name: "延庆区旧县镇白河堡村温馨家园",
          value: [116.115483, 40.579299],
        },
        {
          name: "延庆区香营乡新庄堡村温馨家园",
          value: [116.180336, 40.579168],
        },
        {
          name: "怀柔区琉璃庙镇后山铺村温馨家园",
          value: [116.676574, 40.575987],
        },
        {
          name: "怀柔区琉璃庙镇孙胡沟村温馨家园",
          value: [116.595007, 40.573723],
        },
        {
          name: "延庆区香营乡香营村温馨家园",
          value: [116.151902, 40.570339],
        },
        {
          name: "延庆区刘斌堡乡温馨家园",
          value: [116.22604, 40.567708],
        },
        {
          name: "密云区不老屯镇不老屯村温馨家园",
          value: [116.98257, 40.56754],
        },
        {
          name: "密云区不老屯镇示范温馨家园",
          value: [116.97752, 40.567147],
        },
        {
          name: "密云区不老屯镇燕落村温馨家园",
          value: [116.961243, 40.563992],
        },
        {
          name: "延庆区珍珠泉乡下花楼村温馨家园",
          value: [116.402753, 40.563801],
        },
        {
          name: "延庆区珍珠泉乡温馨家园",
          value: [116.425461, 40.56036],
        },
        {
          name: "延庆区四海镇黑汉岭村温馨家园",
          value: [116.354645, 40.552818],
        },
        {
          name: "延庆区旧县镇米粮屯村温馨家园",
          value: [116.05621, 40.552749],
        },
        {
          name: "延庆区旧县镇温馨家园",
          value: [116.08733, 40.550988],
        },
        {
          name: "延庆区四海镇南湾村温馨家园",
          value: [116.371852, 40.544824],
        },
        {
          name: "密云区石城镇石城村温馨家园",
          value: [116.816456, 40.541804],
        },
        {
          name: "密云区石城镇示范温馨家园",
          value: [116.820183, 40.541477],
        },
        {
          name: "密云区太师屯镇示范温馨家园",
          value: [117.132706, 40.540893],
        },
        {
          name: "密云区太师屯镇葡萄园村温馨家园",
          value: [117.129833, 40.535503],
        },
        {
          name: "延庆区四海镇前山村温馨家园",
          value: [116.349787, 40.534136],
        },
        {
          name: "延庆区四海镇温馨家园",
          value: [116.413324, 40.531586],
        },
        {
          name: "延庆区永宁镇温馨家园",
          value: [116.16295, 40.529043],
        },
        {
          name: "延庆区四海镇四海村温馨家园",
          value: [116.411677, 40.527816],
        },
        {
          name: "延庆区旧县镇大柏老村温馨家园",
          value: [116.07308, 40.52686],
        },
        {
          name: "延庆区四海镇西沟外村温馨家园",
          value: [116.386967, 40.523367],
        },
        {
          name: "怀柔区怀北镇椴树岭村温馨家园",
          value: [116.666539, 40.511937],
        },
        {
          name: "密云区北庄镇示范温馨家园",
          value: [117.180981, 40.509487],
        },
        {
          name: "延庆区四海镇郭家湾村温馨家园",
          value: [116.455846, 40.504179],
        },
        {
          name: "延庆区四海镇永安堡村温馨家园",
          value: [116.447465, 40.50412],
        },
        {
          name: "延庆区四海镇海子口村温馨家园",
          value: [116.400175, 40.495804],
        },
        {
          name: "延庆区永宁镇小庄科村温馨家园",
          value: [116.103316, 40.494734],
        },
        {
          name: "密云区溪翁庄镇北白岩村温馨家园",
          value: [116.811914, 40.483546],
        },
        {
          name: "延庆区张山营镇温馨家园",
          value: [115.844236, 40.476927],
        },
        {
          name: "怀柔区九渡河镇杏树台村温馨家园",
          value: [116.354837, 40.475165],
        },
        {
          name: "密云区溪翁庄镇示范温馨家园",
          value: [116.854124, 40.474803],
        },
        {
          name: "延庆区井庄镇温馨家园",
          value: [116.09321, 40.471771],
        },
        {
          name: "延庆区延庆镇温馨家园",
          value: [115.984552, 40.458923],
        },
        {
          name: "延庆区儒林街道温馨家园",
          value: [115.972989, 40.458666],
        },
        {
          name: "北京市延庆区永华残疾人温馨家园",
          value: [115.974848, 40.456951],
        },
        {
          name: "密云区溪翁庄镇石马峪村温馨家园",
          value: [116.905443, 40.45596],
        },
        {
          name: "怀柔区渤海镇大榛峪村温馨家园",
          value: [116.445674, 40.450034],
        },
        {
          name: "密云区穆家峪镇庄头峪村温馨家园",
          value: [117.015014, 40.449729],
        },
        {
          name: "密云区溪翁庄镇金叵罗村温馨家园",
          value: [116.859943, 40.449123],
        },
        {
          name: "密云区大城子镇苍术会村温馨家园",
          value: [117.18384, 40.44633],
        },
        {
          name: "延庆区百泉街道温馨家园",
          value: [115.980941, 40.440997],
        },
        {
          name: "延庆区大榆树镇温馨家园",
          value: [116.029233, 40.435628],
        },
        {
          name: "怀柔区怀北镇河防口村温馨家园",
          value: [116.673179, 40.435184],
        },
        {
          name: "延庆区大庄科乡温馨家园",
          value: [116.235609, 40.432161],
        },
        {
          name: "怀柔区渤海镇田仙峪村温馨家园",
          value: [116.537897, 40.432064],
        },
        {
          name: "延庆区大榆树镇西杏园村温馨家园",
          value: [115.973277, 40.428162],
        },
        {
          name: "怀柔区怀北镇邓各庄村温馨家园",
          value: [116.704926, 40.4265],
        },
        {
          name: "延庆区康庄镇太平庄村温馨家园",
          value: [115.887692, 40.4198],
        },
        {
          name: "怀柔区渤海镇渤海所村温馨家园",
          value: [116.512923, 40.4164],
        },
        {
          name: "延庆区大榆树镇簸箕营村温馨家园",
          value: [115.971134, 40.415568],
        },
        {
          name: "怀柔区渤海镇温馨家园",
          value: [116.467207, 40.41531],
        },
        {
          name: "密云区穆家峪镇示范温馨家园",
          value: [116.9436, 40.411728],
        },
        {
          name: "怀柔区怀北镇神山村温馨家园",
          value: [116.71793, 40.411669],
        },
        {
          name: "密云区大城子镇碰河寺村温馨家园",
          value: [117.09077, 40.409965],
        },
        {
          name: "怀柔区九渡河镇石湖峪村温馨家园",
          value: [116.320712, 40.40874],
        },
        {
          name: "怀柔区九渡河镇黄花城村温馨家园",
          value: [116.344825, 40.408606],
        },
        {
          name: "密云区西田各庄镇西田各庄村温馨家园",
          value: [116.770779, 40.404459],
        },
        {
          name: "延庆区大榆树镇程家营村温馨家园",
          value: [115.959832, 40.404035],
        },
        {
          name: "延庆区康庄镇刘浩营村温馨家园",
          value: [115.881908, 40.402881],
        },
        {
          name: "怀柔区九渡河镇东宫村温馨家园",
          value: [116.358253, 40.401968],
        },
        {
          name: "延庆区康庄镇马坊村温馨家园",
          value: [115.897086, 40.400145],
        },
        {
          name: "密云区巨各庄镇达峪村温馨家园",
          value: [117.054688, 40.398686],
        },
        {
          name: "怀柔区怀北镇东庄村温馨家园",
          value: [116.700023, 40.392608],
        },
        {
          name: "怀柔区九渡河镇黄花镇村温馨家园",
          value: [116.371199, 40.39161],
        },
        {
          name: "延庆区八达岭镇小浮沱村温馨家园",
          value: [115.975994, 40.388336],
        },
        {
          name: "怀柔区渤海镇六渡河村温馨家园",
          value: [116.535207, 40.384967],
        },
        {
          name: "延庆区康庄镇刁千营村温馨家园",
          value: [115.903646, 40.384582],
        },
        {
          name: "延庆区康庄镇温馨家园",
          value: [115.900231, 40.382595],
        },
        {
          name: "密云区巨各庄镇焦家坞村温馨家园",
          value: [116.948102, 40.381658],
        },
        {
          name: "密云区巨各庄镇示范温馨家园",
          value: [116.948102, 40.381658],
        },
        {
          name: "密云区果园街道温馨家园",
          value: [116.835625, 40.378934],
        },
        {
          name: "密云区果园街道西里社区温馨家园",
          value: [116.835625, 40.378934],
        },
        {
          name: "密云区十里堡镇岭东村温馨家园",
          value: [116.796289, 40.371961],
        },
        {
          name: "密云区巨各庄镇霍各庄村温馨家园",
          value: [116.965899, 40.369207],
        },
        {
          name: "密云区鼓楼街道示范温馨家园",
          value: [116.840981, 40.368879],
        },
        {
          name: "密云区鼓楼街道向阳西社区温馨家园",
          value: [116.840981, 40.368879],
        },
        {
          name: "怀柔区雁栖镇范各庄村温馨家园",
          value: [116.665278, 40.36816],
        },
        {
          name: "怀柔区九渡河镇九渡河村温馨家园",
          value: [116.459378, 40.361414],
        },
        {
          name: "密云区巨各庄镇八家庄村温馨家园",
          value: [116.996163, 40.361167],
        },
        {
          name: "怀柔区九渡河镇黄坎村温馨家园",
          value: [116.46873, 40.360759],
        },
        {
          name: "怀柔区九渡河镇团泉村温馨家园",
          value: [116.441462, 40.359455],
        },
        {
          name: "密云区十里堡镇示范温馨家园",
          value: [116.801167, 40.358224],
        },
        {
          name: "密云区西田各庄镇社会福利中心温馨家园",
          value: [116.787398, 40.356886],
        },
        {
          name: "怀柔区九渡河镇花木村温馨家园",
          value: [116.409706, 40.349188],
        },
        {
          name: "怀柔区九渡河镇局里村温馨家园",
          value: [116.423949, 40.348783],
        },
        {
          name: "平谷区镇罗营镇第三温馨家园",
          value: [117.149529, 40.345111],
        },
        {
          name: "平谷区镇罗营镇温馨家园",
          value: [117.149529, 40.345111],
        },
        {
          name: "平谷区镇罗营镇下营温馨家园",
          value: [117.149529, 40.345111],
        },
        {
          name: "密云区十里堡镇清水潭示范温馨家园",
          value: [116.742488, 40.34505],
        },
        {
          name: "怀柔区九渡河镇四渡河村温馨家园",
          value: [116.480479, 40.340256],
        },
        {
          name: "平谷区镇罗营镇杨家台村温馨家园",
          value: [117.216817, 40.338787],
        },
        {
          name: "密云区河南寨镇两河示范温馨家园",
          value: [116.827206, 40.331294],
        },
        {
          name: "密云区河南寨镇福利中心示范温馨家园",
          value: [116.82178, 40.32881],
        },
        {
          name: "延庆区八达岭镇帮水峪村温馨家园",
          value: [115.940136, 40.325654],
        },
        {
          name: "密云区河南寨镇套里村温馨家园",
          value: [116.821269, 40.324347],
        },
        {
          name: "平谷区镇罗营镇北四道岭村温馨家园",
          value: [117.139175, 40.323847],
        },
        {
          name: "怀柔区泉河街道温馨家园",
          value: [116.634019, 40.321331],
        },
        {
          name: "密云区东邵渠镇石峨村温馨家园",
          value: [116.9667, 40.32022],
        },
        {
          name: "平谷区大华山镇西峪村温馨家园",
          value: [117.087038, 40.320156],
        },
        {
          name: "平谷区大华山镇泉水峪村温馨家园",
          value: [117.084039, 40.300218],
        },
        {
          name: "昌平区十三陵镇（长陵）温馨家园",
          value: [116.25551, 40.297937],
        },
        {
          name: "密云区东邵渠镇示范温馨家园",
          value: [116.967625, 40.29406],
        },
        {
          name: "密云区东邵渠镇东邵渠村温馨家园",
          value: [116.964522, 40.29233],
        },
        {
          name: "平谷区大华山镇砖瓦窑村温馨家园",
          value: [117.077458, 40.290826],
        },
        {
          name: "怀柔区桥梓镇温馨家园",
          value: [116.574072, 40.290497],
        },
        {
          name: "平谷区熊儿寨乡示范温馨家园",
          value: [117.134151, 40.284979],
        },
        {
          name: "平谷区大华山镇第二温馨家园",
          value: [117.065644, 40.277608],
        },
        {
          name: "平谷区大华山中心镇温馨家园",
          value: [117.073183, 40.274569],
        },
        {
          name: "平谷区刘家店镇北吉山村温馨家园",
          value: [116.98676, 40.267912],
        },
        {
          name: "平谷区大华山镇小峪子村温馨家园",
          value: [117.038653, 40.267231],
        },
        {
          name: "平谷区大华山镇陈庄子村温馨家园",
          value: [117.077208, 40.263009],
        },
        {
          name: "平谷区刘家店镇行宫村温馨家园",
          value: [117.001154, 40.256555],
        },
        {
          name: "昌平区南口镇南口村社区温馨家园",
          value: [116.127096, 40.252748],
        },
        {
          name: "昌平区十三陵镇（大宫门）温馨家园",
          value: [116.220952, 40.250181],
        },
        {
          name: "顺义区北石槽镇温馨家园",
          value: [116.535784, 40.248181],
        },
        {
          name: "昌平区南口镇辛力庄村温馨家园",
          value: [116.16407, 40.247377],
        },
        {
          name: "顺义区木林镇温馨家园",
          value: [116.780862, 40.245693],
        },
        {
          name: "平谷区刘家店镇第二温馨家园",
          value: [117.021989, 40.240151],
        },
        {
          name: "平谷区刘家店镇第一温馨家园",
          value: [117.021989, 40.240151],
        },
        {
          name: "昌平区南口镇后桃洼村温馨家园",
          value: [116.090096, 40.236051],
        },
        {
          name: "平谷区黄松峪乡黄松峪温馨家园",
          value: [117.260857, 40.232773],
        },
        {
          name: "顺义区龙湾屯镇温馨家园",
          value: [116.857834, 40.231969],
        },
        {
          name: "昌平区南口镇温馨家园",
          value: [116.147412, 40.230225],
        },
        {
          name: "昌平区南口镇南口镇村温馨家园",
          value: [116.141647, 40.226943],
        },
        {
          name: "平谷区王辛庄镇太后村级温馨家园",
          value: [117.119183, 40.223357],
        },
        {
          name: "城南街道舒心温馨家园",
          value: [116.203089, 40.222574],
        },
        {
          name: "平谷区金海湖镇红石门村温馨家园",
          value: [117.374459, 40.220862],
        },
        {
          name: "平谷区王辛庄镇北辛庄温馨家园",
          value: [117.045152, 40.212989],
        },
        {
          name: "顺义区赵全营镇温馨家园",
          value: [116.593395, 40.212179],
        },
        {
          name: "顺义区牛栏山镇温馨家园",
          value: [116.654898, 40.210377],
        },
        {
          name: "昌平区南邵镇温馨家园",
          value: [116.28625, 40.207746],
        },
        {
          name: "平谷区南独乐河镇峨嵋山村温馨家园",
          value: [117.202026, 40.207527],
        },
        {
          name: "平谷区金海湖镇东上营村温馨家园",
          value: [117.321204, 40.206635],
        },
        {
          name: "平谷区王辛庄镇北上营村级温馨家园",
          value: [117.123945, 40.205715],
        },
        {
          name: "顺义区北小营镇榆林温馨家园",
          value: [116.728641, 40.200771],
        },
        {
          name: "平谷区金海湖镇上宅村温馨家园",
          value: [117.300439, 40.192358],
        },
        {
          name: "平谷区山东庄镇山东庄村温馨家园",
          value: [117.163426, 40.191731],
        },
        {
          name: "顺义区北小营镇前礼务村温馨家园",
          value: [116.713946, 40.189096],
        },
        {
          name: "平谷区山东庄镇温馨家园",
          value: [117.158673, 40.188262],
        },
        {
          name: "平谷区王辛庄镇熊耳营村级温馨家园",
          value: [117.114212, 40.188206],
        },
        {
          name: "平谷区峪口镇残疾人温馨家园",
          value: [117.008759, 40.184046],
        },
        {
          name: "顺义区张镇驻马庄温馨家园",
          value: [116.883247, 40.183876],
        },
        {
          name: "昌平区马池口镇温馨家园",
          value: [116.168653, 40.182994],
        },
        {
          name: "昌平区流村镇（博源）温馨家园",
          value: [116.064631, 40.182973],
        },
        {
          name: "昌平区流村镇(国富）温馨家园",
          value: [116.064631, 40.182973],
        },
        {
          name: "平谷区金海湖镇第三温馨家园",
          value: [117.266577, 40.180065],
        },
        {
          name: "平谷区金海湖镇第一温馨家园",
          value: [117.266577, 40.180065],
        },
        {
          name: "平谷区金海湖镇郭家屯温馨家园",
          value: [117.266577, 40.180065],
        },
        {
          name: "平谷区峪口镇坨头寺村温馨家园",
          value: [116.978273, 40.179121],
        },
        {
          name: "平谷区王辛庄镇太平庄静鑫温馨家园",
          value: [117.089614, 40.178076],
        },
        {
          name: "平谷区南独乐河镇温馨家园",
          value: [117.226961, 40.172301],
        },
        {
          name: "顺义区马坡镇温馨家园",
          value: [116.657883, 40.171189],
        },
        {
          name: "顺义区双丰街道温馨家园",
          value: [116.662561, 40.16756],
        },
        {
          name: "昌平区阳坊镇西马坊村温馨家园",
          value: [116.144874, 40.164704],
        },
        {
          name: "平谷区王辛庄镇第一温馨家园",
          value: [117.103843, 40.160837],
        },
        {
          name: "昌平区流村镇马刨泉村温馨家园",
          value: [115.878608, 40.156845],
        },
        {
          name: "平谷区滨河街道温馨家园",
          value: [117.119338, 40.156561],
        },
        {
          name: "平谷区兴谷街道温馨家园",
          value: [117.119086, 40.156357],
        },
        {
          name: "顺义区高丽营镇温馨家园",
          value: [116.550935, 40.147923],
        },
        {
          name: "平谷区大兴庄镇温馨家园",
          value: [117.041972, 40.145243],
        },
        {
          name: "平谷区平谷镇温馨家园",
          value: [117.100524, 40.1398],
        },
        {
          name: "昌平区阳坊镇温馨家园",
          value: [116.139323, 40.138556],
        },
        {
          name: "顺义区南法信镇北法信村温馨家园",
          value: [116.611562, 40.137837],
        },
        {
          name: "阳坊镇西贯市残疾人温馨家园",
          value: [116.147976, 40.136826],
        },
        {
          name: "平谷区夏各庄镇温馨家园",
          value: [117.14245, 40.136219],
        },
        {
          name: "顺义区南彩镇温馨家园",
          value: [116.711294, 40.135011],
        },
        {
          name: "平谷区马昌营镇温馨家园",
          value: [117.021483, 40.134557],
        },
        {
          name: "顺义区杨镇汉石桥温馨家园",
          value: [116.796615, 40.133381],
        },
        {
          name: "平谷区平谷镇赵各庄温馨家园",
          value: [117.088839, 40.13156],
        },
        {
          name: "顺义区光明街道温馨家园",
          value: [116.66205, 40.13015],
        },
        {
          name: "昌平区阳坊镇后白虎涧村温馨家园",
          value: [116.123222, 40.127353],
        },
        {
          name: "顺义区旺泉街道温馨家园",
          value: [116.64186, 40.12412],
        },
        {
          name: "平谷区平谷镇东鹿角温馨家园",
          value: [117.094911, 40.12119],
        },
        {
          name: "顺义区胜利街道温馨家园",
          value: [116.650569, 40.12027],
        },
        {
          name: "平谷区夏各庄镇稻地村温馨家园",
          value: [117.145095, 40.114333],
        },
        {
          name: "顺义区石园街道温馨家园",
          value: [116.66009, 40.11238],
        },
        {
          name: "石景山区鲁谷街道温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "房山区凤山温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "房山区天星温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "房山区西潞街道温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "昌平区回龙观街道（融泽嘉园）温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "东风街道温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "迎风街道温馨家园",
          value: [116.392533, 40.107145],
        },
        {
          name: "昌平区北七家镇温馨家园",
          value: [116.407528, 40.105747],
        },
        {
          name: "昌平区天通苑北街道温馨家园",
          value: [116.407528, 40.105747],
        },
        {
          name: "昌平区百善镇温馨家园",
          value: [116.392349, 40.10573],
        },
        {
          name: "昌平区东小口镇温馨家园",
          value: [116.392349, 40.10573],
        },
        {
          name: "昌平区沙河镇温馨家园",
          value: [116.392349, 40.10573],
        },
        {
          name: "昌平区小汤山镇温馨家园",
          value: [116.392349, 40.10573],
        },
        {
          name: "昌平区兴寿镇温馨家园",
          value: [116.392349, 40.10573],
        },
        {
          name: "顺义区南法信镇港馨东区温馨家园",
          value: [116.691831, 40.10509],
        },
        {
          name: "顺义区后沙峪镇温馨家园",
          value: [116.544075, 40.104689],
        },
        {
          name: "平谷区东高村镇第二温馨家园",
          value: [117.114084, 40.103172],
        },
        {
          name: "平谷区东高村镇第一温馨家园",
          value: [117.114084, 40.103172],
        },
        {
          name: "海淀区上庄镇李家坟温馨家园",
          value: [116.228814, 40.102371],
        },
        {
          name: "顺义区仁和镇河南村温馨家园",
          value: [116.697869, 40.101745],
        },
        {
          name: "昌平区流村镇韩台村温馨家园",
          value: [115.970569, 40.101104],
        },
        {
          name: "海淀区苏家坨镇聂各庄温馨家园",
          value: [116.117605, 40.100594],
        },
        {
          name: "顺义区空港街道温馨家园",
          value: [116.555759, 40.100451],
        },
        {
          name: "昌平区天通苑南街道温馨家园",
          value: [116.380234, 40.098502],
        },
        {
          name: "海淀区上庄镇温馨家园",
          value: [116.215342, 40.096793],
        },
        {
          name: "顺义区李遂镇温馨家园",
          value: [116.757286, 40.090974],
        },
        {
          name: "顺义区大孙各庄镇温馨家园",
          value: [116.919496, 40.087181],
        },
        {
          name: "海淀区苏家坨镇温馨家园",
          value: [116.15807, 40.079785],
        },
        {
          name: "昌平区回龙观街道（金榜园）温馨家园",
          value: [116.328478, 40.061503],
        },
        {
          name: "顺义区李桥镇温馨家园",
          value: [116.644316, 40.060613],
        },
        {
          name: "平谷区马坊镇温馨家园",
          value: [117.011806, 40.060156],
        },
        {
          name: "平谷区马坊镇二条街示范温馨家园",
          value: [117.01454, 40.053297],
        },
        {
          name: "顺义区天竺镇温馨家园",
          value: [116.588744, 40.048906],
        },
        {
          name: "海淀区温泉镇温馨家园",
          value: [116.18103, 40.04876],
        },
        {
          name: "海淀区西北旺镇温馨家园",
          value: [116.263546, 40.048611],
        },
        {
          name: "朝阳区双井街道垂杨柳东里社区温馨家园",
          value: [116.50344, 40.017327],
        },
        {
          name: "朝阳区望京街道花家地社区温馨家园",
          value: [116.50344, 40.017327],
        },
        {
          name: "朝阳区崔各庄地区温馨家园",
          value: [116.501239, 40.016732],
        },
        {
          name: "朝阳区安贞街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区奥运村街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区常营地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区朝外街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区大屯街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区东坝地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区东风地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区东湖街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区豆各庄地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区垡头街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区高碑店地区高碑店社区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区高碑店地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区管庄地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区建外街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区金盏地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区劲松街道农光里社区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区酒仙桥街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区来广营地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区六里屯街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区麦子店街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区南磨房地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区平房地区石各庄村温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区三间房地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区三里屯街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区首都机场街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区孙河地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区太阳宫地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区王四营地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区望京街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区香河园街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区小关街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区小红门地区鸿博家园社区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区小红门地区温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "朝阳区亚运村街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "大兴区博兴街道温馨家园",
          value: [116.500836, 40.016675],
        },
        {
          name: "门头沟区军庄镇孟悟村温馨家园",
          value: [116.126484, 40.011722],
        },
        {
          name: "海淀区八里庄街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区北下关街道动物园社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区北下关街道钢研社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区海淀街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区海淀街道友谊社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区花园路街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区马连洼街道菊园社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区马连洼街道肖家河社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区青龙桥街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区清河街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区清华园街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区上地街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区曙光街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区万寿路街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区西三旗街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区香山街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区学院路街道六道口社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区学院路街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区永定路街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区中关村街道温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区紫竹院街道车南里社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "海淀区紫竹院街道魏北社区温馨家园",
          value: [116.281285, 40.002728],
        },
        {
          name: "朝阳区八里庄街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区常营地区民族家园社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区和平街街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区黑庄户地区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区呼家楼街道呼家楼北社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区六里屯街道秀水园社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区潘家园街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区平房地区平房社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区平房地区姚家园西社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区三里屯街道幸福二村社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区团结湖街道水碓子社区温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "朝阳区左家庄街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "石景山区广宁街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "延庆区香水园街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "向阳街道温馨家园",
          value: [116.530086, 39.9965],
        },
        {
          name: "海淀区东升镇温馨家园",
          value: [116.334383, 39.995077],
        },
        {
          name: "门头沟区斋堂镇东胡林村村级温馨家园",
          value: [115.754291, 39.98775],
        },
        {
          name: "门头沟区斋堂镇残疾人温馨家园",
          value: [115.696525, 39.972404],
        },
        {
          name: "东城区北新桥街道温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "东城区东花市街道温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "东城区东四街道温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "东城区东直门街道温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "东城区和平里街道温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "朝阳区将台地区温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "朝阳区团结湖街道三四条社区温馨家园",
          value: [116.467341, 39.959477],
        },
        {
          name: "门头沟区龙泉镇三家店温馨家园",
          value: [116.110691, 39.959049],
        },
        {
          name: "门头沟区大台玉皇庙温馨家园",
          value: [115.914108, 39.956813],
        },
        {
          name: "石景山区五里坨街道温馨家园",
          value: [116.126154, 39.956479],
        },
        {
          name: "海淀区四季青镇温馨家园",
          value: [116.274675, 39.954559],
        },
        {
          name: "门头沟区城子温馨家园",
          value: [116.101436, 39.953],
        },
        {
          name: "门头沟区清水镇下清水村温馨家园",
          value: [115.630528, 39.945025],
        },
        {
          name: "东城区安定门街道温馨家园",
          value: [116.413039, 39.944224],
        },
        {
          name: "通州区宋庄镇德泽福润温馨家园残疾人服务中心",
          value: [116.727373, 39.940984],
        },
        {
          name: "通州区宋庄镇福乐温馨家园残疾人服务中心",
          value: [116.727373, 39.940984],
        },
        {
          name: "门头沟区区残联温馨家园",
          value: [116.102009, 39.940646],
        },
        {
          name: "门头沟区斋堂镇马兰村村级温馨家园",
          value: [115.694832, 39.937063],
        },
        {
          name: "东城区交道口街道温馨家园",
          value: [116.40098, 39.93576],
        },
        {
          name: "西城区什刹海街道刘海温馨家园",
          value: [116.380275, 39.93399],
        },
        {
          name: "石景山区苹果园街道西井社区温馨家园",
          value: [116.197853, 39.933564],
        },
        {
          name: "石景山区苹果园街道海特花园第一社区温馨家园",
          value: [116.188799, 39.933031],
        },
        {
          name: "西城区展览路街道百万庄社区友爱港湾",
          value: [116.34274, 39.93276],
        },
        {
          name: "西城区展览路街道慧馨园",
          value: [116.336357, 39.93166],
        },
        {
          name: "门头沟区龙泉镇大峪村温馨家园",
          value: [116.107086, 39.930844],
        },
        {
          name: "石景山区苹果园街道温馨家园",
          value: [116.195114, 39.930182],
        },
        {
          name: "石景山区苹果园街道苹三社区温馨家园",
          value: [116.180541, 39.928634],
        },
        {
          name: "东城区景山街道康健阳光家园",
          value: [116.410591, 39.92696],
        },
        {
          name: "石景山区金顶街街道温馨家园",
          value: [116.168437, 39.926023],
        },
        {
          name: "朝阳区呼家楼街道东大桥社区温馨家园",
          value: [116.45116, 39.921526],
        },
        {
          name: "东城区朝阳门街道温馨家园",
          value: [116.430464, 39.920681],
        },
        {
          name: "东城区东华门街道温馨家园",
          value: [116.409156, 39.916391],
        },
        {
          name: "石景山区八角街道馨蓝温馨家园",
          value: [116.196193, 39.91568],
        },
        {
          name: "西城区月坛街道爱心服务中心",
          value: [116.344706, 39.915014],
        },
        {
          name: "西城区月坛街道静心园",
          value: [116.344706, 39.915014],
        },
        {
          name: "西城区西长安街街道阳光雨温馨家园",
          value: [116.380624, 39.913217],
        },
        {
          name: "石景山区古城街道温馨家园",
          value: [116.188462, 39.911362],
        },
        {
          name: "石景山区老山街道温馨家园",
          value: [116.222361, 39.91026],
        },
        {
          name: "西城区月坛街道三里河1区社区温馨家园",
          value: [116.337293, 39.90956],
        },
        {
          name: "西城区月坛街道手拉手康复活动中心（益智园）",
          value: [116.346895, 39.903588],
        },
        {
          name: "西城区白纸坊街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区椿树街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区德胜街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区广内街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区广外街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区金融街街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区牛街街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区什刹海街道护国寺温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区陶然亭街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区天桥街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区西长安街街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区月坛街道社会路社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区展览路街道扣钟社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区展览路街道三塔社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "西城区展览路街道团结社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "海淀区北太平庄街道罗庄社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "海淀区甘家口街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "海淀区田村路街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "海淀区学院路街道展春园社区温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "海淀区羊坊店街道温馨家园",
          value: [116.353197, 39.903361],
        },
        {
          name: "石景山区八宝山街道温馨家园",
          value: [116.236289, 39.901516],
        },
        {
          name: "东城区崇文门外街道温馨家园",
          value: [116.427412, 39.894626],
        },
        {
          name: "丰台区卢沟桥街道大井社区残疾人温馨家园",
          value: [116.262132, 39.894401],
        },
        {
          name: "丰台区卢沟桥街道青塔地区残疾人温馨家园",
          value: [116.262132, 39.894401],
        },
        {
          name: "东城区前门街道温馨家园",
          value: [116.407186, 39.893921],
        },
        {
          name: "丰台区东铁匠营街道刘家窑第一社区温馨家园",
          value: [116.381386, 39.88984],
        },
        {
          name: "东城区天坛街道温馨家园",
          value: [116.399136, 39.889329],
        },
        {
          name: "房山区大安山乡温馨家园",
          value: [115.78626, 39.888029],
        },
        {
          name: "东城区建国门街道残疾人温馨家园",
          value: [116.43869, 39.88303],
        },
        {
          name: "东城区龙潭街道温馨家园",
          value: [116.436021, 39.882868],
        },
        {
          name: "门头沟区潭柘寺镇赵家台温馨家园",
          value: [116.031617, 39.880268],
        },
        {
          name: "丰台区右安门街道玉林东里二社区温馨家园",
          value: [116.36136, 39.867085],
        },
        {
          name: "通州区梨园镇温馨家园",
          value: [116.662951, 39.866859],
        },
        {
          name: "东城区永定门外街道温馨家园",
          value: [116.402031, 39.865631],
        },
        {
          name: "丰台区西罗园街道花椒树社区温馨家园",
          value: [116.385859, 39.86166],
        },
        {
          name: "丰台区方庄街道芳星园三区温馨家园",
          value: [116.435475, 39.860612],
        },
        {
          name: "丰台区大红门街道温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区东高地街道温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区丰台街道63号院社区温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区丰台街道北大街社区温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区马家堡街道温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区太平桥街道太平桥西里社区温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区宛平街道温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区云岗街道温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区长辛店街道东南街社区温馨家园",
          value: [116.18998, 39.857526],
        },
        {
          name: "丰台区长辛店街道车辆厂社区温馨家园",
          value: [116.197424, 39.850171],
        },
        {
          name: "丰台区新村街道桥梁厂第二社区温馨家园",
          value: [116.332887, 39.848952],
        },
        {
          name: "通州区永顺镇温馨家园",
          value: [116.552194, 39.832164],
        },
        {
          name: "房山区史家营乡元阳水温馨家园",
          value: [115.747474, 39.822624],
        },
        {
          name: "房山区佛子庄乡温馨家园",
          value: [115.890306, 39.820841],
        },
        {
          name: "房山区河北镇福生缘温馨家园",
          value: [115.938399, 39.819764],
        },
        {
          name: "房山区河北镇弦月温馨家园",
          value: [115.938399, 39.819764],
        },
        {
          name: "大兴区旧宫镇温馨家园",
          value: [116.436516, 39.812895],
        },
        {
          name: "房山区河北镇辛庄温馨家园",
          value: [115.926044, 39.811544],
        },
        {
          name: "丰台区云岗大灰厂社区温馨家园",
          value: [116.162726, 39.807354],
        },
        {
          name: "丰台区王佐镇温馨家园",
          value: [116.156148, 39.801607],
        },
        {
          name: "通州区西集镇温馨家园",
          value: [116.839759, 39.80035],
        },
        {
          name: "丰台区和义街道残疾人温馨家园",
          value: [116.300836, 39.797901],
        },
        {
          name: "房山区青龙湖镇水峪温馨家园",
          value: [116.039597, 39.786474],
        },
        {
          name: "通州区漷县镇温馨家园",
          value: [116.793387, 39.780053],
        },
        {
          name: "房山区青龙湖镇温馨家园",
          value: [116.039634, 39.777311],
        },
        {
          name: "房山区南窖乡温馨家园",
          value: [115.842039, 39.768733],
        },
        {
          name: "房山区长阳镇温馨家园",
          value: [116.217065, 39.76623],
        },
        {
          name: "大兴区瀛海镇温馨家园",
          value: [116.452695, 39.763483],
        },
        {
          name: "房山区青龙湖镇沙窝村温馨家园",
          value: [116.03895, 39.7572],
        },
        {
          name: "大兴区黄村镇温馨家园",
          value: [116.340296, 39.743298],
        },
        {
          name: "大兴区西红门镇东区温馨家园",
          value: [116.549456, 39.740346],
        },
        {
          name: "大兴区西红门镇西区温馨家园",
          value: [116.549456, 39.740346],
        },
        {
          name: "大兴区高米店街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区观音寺街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区林校路街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区清源街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区荣华街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区天宫院街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "大兴区兴丰街道温馨家园",
          value: [116.547819, 39.739249],
        },
        {
          name: "房山区良乡镇福港温馨家园",
          value: [116.144076, 39.734982],
        },
        {
          name: "房山区良乡镇温馨家园",
          value: [116.144076, 39.734982],
        },
        {
          name: "房山区蒲洼乡残疾人温馨家园",
          value: [115.535707, 39.733992],
        },
        {
          name: "通州区北苑街道温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "通州区马驹桥镇温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "通州区于家务乡温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "通州区玉桥街道温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "通州区中仓街道温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "大兴区亦庄镇温馨家园",
          value: [116.561731, 39.727915],
        },
        {
          name: "房山区霞云岭温馨家园",
          value: [115.745909, 39.727581],
        },
        {
          name: "房山区阎村镇小董村温馨家园",
          value: [116.066488, 39.721695],
        },
        {
          name: "房山区阎村镇安晟温馨家园",
          value: [116.092378, 39.716162],
        },
        {
          name: "房山区阎村镇康宇温馨家园",
          value: [116.092378, 39.716162],
        },
        {
          name: "通州区永乐店镇温馨家园",
          value: [116.796586, 39.709424],
        },
        {
          name: "房山区城关街道北关温馨家园",
          value: [116.013775, 39.705443],
        },
        {
          name: "房山区城关街道洪寺温馨家园",
          value: [116.013775, 39.705443],
        },
        {
          name: "房山区城关街道京东温馨家园",
          value: [116.013775, 39.705443],
        },
        {
          name: "房山区拱辰街道梨村温馨家园",
          value: [116.18494, 39.70323],
        },
        {
          name: "大兴区北臧村镇温馨家园",
          value: [116.302622, 39.68623],
        },
        {
          name: "大兴区青云店镇温馨家园",
          value: [116.501604, 39.685979],
        },
        {
          name: "大兴区长子营镇温馨家园",
          value: [116.596316, 39.685968],
        },
        {
          name: "房山区周口店镇西七村温馨家园",
          value: [115.951376, 39.680913],
        },
        {
          name: "房山区周口店镇西庄温馨家园",
          value: [115.951376, 39.680913],
        },
        {
          name: "房山区长阳镇普乐园温馨家园",
          value: [116.207981, 39.677594],
        },
        {
          name: "房山区石楼镇夏村汇馨温馨家园",
          value: [116.03286, 39.66678],
        },
        {
          name: "房山区窦店镇大高舍温馨家园",
          value: [116.106679, 39.66561],
        },
        {
          name: "房山区周口店镇娄子水温馨家园",
          value: [115.916027, 39.665338],
        },
        {
          name: "房山区十渡镇平峪村温馨家园",
          value: [115.551314, 39.664682],
        },
        {
          name: "房山区周口店镇大韩继村温馨家园",
          value: [115.947815, 39.658161],
        },
        {
          name: "房山区石楼镇坨头村温馨家园",
          value: [115.999527, 39.65731],
        },
        {
          name: "房山区石楼镇襄福阳光温馨家园",
          value: [115.984934, 39.655569],
        },
        {
          name: "大兴区采育镇温馨家园",
          value: [116.666085, 39.653165],
        },
        {
          name: "房山区窦店镇板桥温馨家园",
          value: [116.052953, 39.649148],
        },
        {
          name: "房山区窦店镇窦店村温馨家园",
          value: [116.067699, 39.644074],
        },
        {
          name: "房山区窦店镇兴隆庄村温馨家园",
          value: [116.154517, 39.640498],
        },
        {
          name: "房山区十渡镇残疾人温馨家园",
          value: [115.58934, 39.639844],
        },
        {
          name: "大兴区庞各庄镇老中学温馨家园",
          value: [116.325886, 39.632057],
        },
        {
          name: "大兴区魏善庄镇温馨家园",
          value: [116.414675, 39.63148],
        },
        {
          name: "房山区窦店镇白草洼温馨家园",
          value: [116.049645, 39.625956],
        },
        {
          name: "房山区琉璃河镇西地村温馨家园",
          value: [116.139147, 39.622462],
        },
        {
          name: "大兴区安定镇温馨家园",
          value: [116.499929, 39.617623],
        },
        {
          name: "大兴区采育镇温馨家园凤河营分园",
          value: [116.692914, 39.614156],
        },
        {
          name: "房山区琉璃河镇温馨家园",
          value: [116.030997, 39.610514],
        },
        {
          name: "房山区琉璃河镇鑫成晟泷温馨家园",
          value: [116.030997, 39.610514],
        },
        {
          name: "房山区琉璃河镇庄头金利温馨家园",
          value: [116.030997, 39.610514],
        },
        {
          name: "大兴区庞各庄镇定福庄温馨家园",
          value: [116.277827, 39.604896],
        },
        {
          name: "房山区韩村河镇温馨家园",
          value: [115.96311, 39.603775],
        },
        {
          name: "房山区琉璃河镇李庄康乐温馨家园",
          value: [116.003198, 39.598652],
        },
        {
          name: "房山区大石窝镇后石门温馨家园",
          value: [115.808991, 39.592158],
        },
        {
          name: "房山区长沟镇温馨家园",
          value: [115.900843, 39.580167],
        },
        {
          name: "房山区张坊温馨家园",
          value: [115.710293, 39.571532],
        },
        {
          name: "房山区南白岱温馨家园",
          value: [115.746118, 39.566599],
        },
        {
          name: "大兴区礼贤镇温馨家园",
          value: [116.435564, 39.556618],
        },
        {
          name: "房山区大石窝镇王家磨温馨家园",
          value: [115.728169, 39.556559],
        },
        {
          name: "房山区大石窝镇半壁店村温馨家园",
          value: [115.838123, 39.555716],
        },
        {
          name: "房山区大石窝镇温馨家园",
          value: [115.826039, 39.552764],
        },
        {
          name: "大兴区榆垡镇温馨家园",
          value: [116.315693, 39.511323],
        },
      ],
    }
  },
  mounted() {
    this.mapOptions()
  },
  methods: {
    mapOptions() {
      var chartDom = document.getElementById("bj")
      var myChart = echarts.init(chartDom)
      var option
      option = {
        visualMap: {
          min: 0,
          max: 10,
          show: false,
          seriesIndex: 0,
          // 颜色
          inRange: {
            color: ["rgba(41,166,206, .5)", "rgba(69,117,245, .9)"],
          },
        },
        geo: {
          show: false,
          map: "北京",
          // label: {
          //   normal: {
          //     show: false,
          //   },
          //   emphasis: {
          //     show: false,
          //   },
          // },
          // roam: true,
          // itemStyle: {
          //   show: true,
          //   normal: {
          //     areaColor: "transparent",
          //     borderColor: "#3fdaff",
          //     borderWidth: 2,
          //     shadowColor: "rgba(63, 218, 255, 0.5)",
          //     shadowBlur: 30,
          //   },
          //   emphasis: {
          //     areaColor: "#2B91B7",
          //   },
          // },
        },
        series: [
          {
            type: "map",
            // map:"北京",
            roam: false,
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 15,
                  fontWeight: 500,
                },
              },
              emphasis: {
                textStyle: {
                  color: "rgb(183,185,14)",
                },
              },
            },

            itemStyle: {
              normal: {
                // areaColor: "#003669",
                 areaColor: "rgba(73,86,166,.1)",
                borderColor: "#3fdaff",
                borderWidth: 2,
                shadowColor: "rgba(63, 218, 255,0.6)",
                shadowBlur: 35,
              },
              emphasis: {
                areaColor: "#2B91B7",
              },
            },
            zoom: 1.1,
            mapType: "北京", //使用
          },
          {
            type: "scatter",
            coordinateSystem: "geo",
            showEffectOn: "render",
            zlevel: 1,
            rippleEffect: {
              period: 15,
              scale: 4,
              brushType: "fill",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                offset: [15, 0],
                color: "#1DE9B6",
                show: false,
              },
            },
            itemStyle: {
              normal: {
                // color: "#F58413",
                color: "#FBDF00",
                // shadowBlur: 20,
                // shadowColor: "#333",
              },
            },
            symbolSize: 12,
            symbol: "pin",
            data: this.points,
          }, //地图线的动画效果
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
      myChart.on("click", (params) => {
        console.log(params)
        // if (params.name === "怀柔") {
        this.$router.push({ path: "/huairou" })
        // }

        // this.$emit("onclick")
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.centerPage {
  // margin-top: 1.25rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}
.map {
  width: 100%;
  height: 460px;
}
.centerBottom {
  display: flex;
  margin-bottom: 1.25rem;
  margin-top: 4.375rem;
  width: 100%;
  height: 16.25rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      position: relative;
      height: 7.8125rem;
      padding: 0 0.625rem;
      width: 32%;
      border-radius: 5px;
      border: 1px solid #343f4b;
      background-color: rgba(19, 25, 47, 0.8);
      img {
        width: 6.25rem;
        height: 6.25rem;
      }
      .detail-item-text {
        margin-left: 0.625rem;
        h3 {
          color: #bcdcff;
          font-size: 16px;
          margin-bottom: 1.25rem;
        }
        span {
          font-weight: 500px;
          font-size: 1.25rem;
          font-weight: bolder;
          background: linear-gradient(to bottom, #fff, #4db6e5);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        .unit {
          font-size: 1rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
}
</style>
