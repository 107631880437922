<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">风险预警</span>
          <!-- <dv-decoration-1 class="dv-dec-1" /> -->
        </div>
      </div>
      <div>
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex" style="display:flex;just-contentfy:center">
            <!-- <span class="coin" style="width:1.5rem"> </span> -->
            <!-- #32BAA4 -->
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">({{ item.unit }})</span>
          </p>
        </div>
        <!-- <CenterLeft2Chart /> -->
        <!-- <div id="peixun" style="width:100%;height:13.25rem;"></div> -->
      </div>
      <div class="d-flex pl-2" style="padding-top:30px">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">培训人数随时间变化图</span>
        </div>
      </div>
      <div class="d-flex jc-center">
        <!-- <CenterLeft2Chart /> -->
        <div id="peixun" style="width:100%;height:12.25rem;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
const formatter = (number) => {
  const numbers = number
    .toString()
    .split("")
    .reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""))

  return segs
    .join(",")
    .split("")
    .reverse()
    .join("")
}
// import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
export default {
  components: {
    // CenterLeft2Chart
  },
  data() {
    return {
      numberData: [
        {
          number: {
            number: [65],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "本月到期",
          unit: "人",
        },

        {
          number: {
            number: [163],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "一年内退休",
          unit: "人",
        },
        {
          number: {
            number: [21],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "培训超期",
          unit: "人",
        },
        {
          number: {
            number: [589],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "培训合格待就业",
          unit: "人",
        },
      ],
    }
  },
  mounted() {
    this.optionPeixun()
  },
  methods: {
    optionPeixun() {
      var chartDom = document.getElementById("peixun")
      var myChart = echarts.init(chartDom)

      let label = [
        "2021.10",
        "2021.11",
        "2021.12",
        "2022.1",
        "2022.2",
        "2022.3",
        "2022.4",
        "2022.5",
        "2022.6",
        "2022.7",
        "2022.8",
        "2022.9",
        "2022.10",
        "2022.11",
        "2022.12",
        "2023.1",
        "2023.2",
        "2023.3",
      ]
      let value = [
        530,
        347,
        369,
        303,
        253,
        296,
        258,
        230,
        111,
        290,
        639,
        821,
        1023,
        456,
        389,
        478,
        589,
        308,
      ]

      var option = {
        grid: {
          top: "10%",
          left: "3%",
          right: "3%",
          bottom: "2%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255,255,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              fontSize: 14,
              textStyle: {
                color: "#7ec7ff",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#3C81FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#243753",
              },
            },
            axisTick: {
              show: false,
            },
            data: label,
          },
        ],
        yAxis: [
          {
            boundaryGap: false,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#7ec7ff",
              },
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                color: "#3C81FF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#283352",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "人数",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            itemStyle: {
              color: "#19a3df",
              borderColor: "#a3c8d8",
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#19a3df",
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(88,255,255,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(88,255,255,0)",
                    },
                  ],
                  false
                ),
              },
            },
            data: value,
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 450px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #fff;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  .item-box {
    & > div {
      padding-right: 5px;
    }
    font-size: 10px;
    float: right;
    position: relative;
    width: 50%;
    color: #d3d6dd;
    .dv-digital-flop {
      width: 100%;
      height: 30px;
    }
    // 金币
    .coin {
      position: relative;
      top: 6px;
      font-size: 20px;
      color: #ffc107;
    }
    .colorYellow {
      color: yellowgreen;
    }
    p {
      text-align: center;
    }
  }
}
</style>
