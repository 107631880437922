<template>
  <div id="bottomRight">
    <dv-border-box-13 class="content">
      <div class="bg-color-black">
        <div class="d-flex pt-2 pl-2">
          <span>
            <icon name="align-left" class="text-icon"></icon>
          </span>
          <span class="fs-xl text mx-2">年度培训数据</span>
        </div>
        <div class="year_zhaop">
          <div>
            <p class="ml-3 colorBlue fw-b fs-xl">全年目标/完成情况</p>
            <div class="year_data">
              2,678/25,000<span class="unit">人次</span>
            </div>
          </div>
          <div>
            <p class="ml-3 colorBlue fw-b fs-xl">全年劳动产品销售情况</p>
            <div class="year_data">
              63.2/400<span class="unit">万元</span>
            </div>
          </div>
          <!-- <div class="zfeed">
            <Feed chart-id="feed" :chart-data="peixun"></Feed>
          </div> -->
        </div>

        <dv-scroll-board
          :config="peixunList"
          style="width:100%;height:360px;margin-top:10px"
        />
      </div>
    </dv-border-box-13>
    <dv-border-box-13 class="content">
      <div class="bg-color-black">
        <div class="d-flex pt-2 pl-2">
          <span>
            <icon name="align-left" class="text-icon"></icon>
          </span>
          <span class="fs-xl text mx-2">年度招聘运营数据</span>
        </div>

        <div class="year_zhaop">
          <div>
            <p class="colorBlue fw-b fs-xl">全年目标/完成情况</p>
            <div class="year_data">
              8,666/13,500<span class="unit">人</span>
            </div>
          </div>
          <div>
            <p class="colorBlue fw-b fs-xl">当前运营完成情况</p>
            <div class="year_data">92/124<span class="unit">件</span></div>
          </div>
        </div>
        <div class="d-flex pt-2 pl-2" style="margin-top:10px">
          <span>
            <icon name="align-left" class="text-icon"></icon>
          </span>
          <span class="fs-xl text mx-2">就业残疾人类型分布</span>
        </div>
        <!-- <dv-scroll-board
          :config="zhaopinList"
          style="width:100%;height:360px;margin-top:30px"
        /> -->
        <div style="width:100%;height:360px;" id="dangjian"></div>
      </div>
    </dv-border-box-13>
    <!-- <div class="bg-color-black"></div> -->
  </div>
</template>

<script>
// import * as echarts from "echarts"
// import Feed from "../components/rightPage/feed.vue"
import * as echarts from "echarts"

export default {
  components: {  },
  data() {
    return {
      zhaopin: {
        title: "",
        number: 72.18,
      },
      peixun: {
        title: "",
        number: 10.71,
      },
      zhaopinList: {
        header: ["地区", "岗位", "完成情况"],
        data: [
          ["行1列1", "行1列2", "行1列3"],
          ["行2列1", "行2列2", "行2列3"],
          ["行3列1", "行3列2", "行3列3"],
          ["行4列1", "行4列2", "行4列3"],
          ["行5列1", "行5列2", "行5列3"],
          ["行6列1", "行6列2", "行6列3"],
          ["行7列1", "行7列2", "行7列3"],
          ["行8列1", "行8列2", "行8列3"],
          ["行9列1", "行9列2", "行9列3"],
          ["行10列1", "行10列2", "行10列3"],
        ],
        index: true,
        columnWidth: [50],
        align: ["center"],
        headerBGC: "#5689EA",
        oddRowBGC: "",
        evenRowBGC: "",
      },
      peixunList: {
        header: ["地区", "培训内容", "培训周期"],
        data: [
          ["通州区", "马驹桥镇网络直播培训", "2023-03-27"],
          ["延庆区", "延庆区残联面点制作职业技能培训", "2023-03-27"],
          ["延庆区", "延庆区残联收纳整理职业技能培训", "2023-03-23"],
          ["通州区", "马驹桥镇缝纫培训", "2023-03-22"],
          ["延庆区", "延庆区残联美甲职业技能培训", "2023-03-20"],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第三场",
            "2023-03-17",
          ],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第二场",
            "2023-03-16",
          ],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第一场",
            "2023-03-15",
          ],
          ["延庆区", "延庆区千家店镇插花培训", "2023-03-07"],
          ["通州区", "马驹桥镇中式面点培训", "2023-03-06"],
        ],
        index: true,
        columnWidth: [50, 80, 200, 100],
        align: ["center"],
        headerBGC: "#5689EA",
        oddRowBGC: "",
        evenRowBGC: "",
      },
    }
  },
  mounted() {
    this.dangjian()
  },
  methods: {
    dangjian() {
      var chartDom = document.getElementById("dangjian")
      var myChart = echarts.init(chartDom)
      var option = {
        title: {},
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "15%",
          right: "5%",
          left: "10%",
          bottom: "20%",
        },
        xAxis: [
          {
            type: "category",
            data: [
              "农村地区就业",
              "辅助性就业",
              "大学生就业",
              "公益岗残疾人",
              "公益岗家属",
            ],
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisLabel: {
              margin: 10,
              color: "#e2e9ff",
              textStyle: {
                fontSize: 14,
              },
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            name: "人",
            axisLabel: {
              formatter: "{value}",
              color: "#e2e9ff",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,1)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: "完成就业",
            data: [6223, 1482, 247, 268, 446],
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,244,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,77,167,1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [10, 10, 10, 10],
                shadowColor: "rgba(0,160,221,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                width: 80,
                height: 20,
                backgroundColor: "rgba(0,160,221,0.1)",
                borderRadius: 200,
                position: ["-48", "-30"],
                distance: 1,
                formatter: ["    {d|●}", " {a|{c}}     \n", "    {b|}"].join(
                  ","
                ),
                rich: {
                  d: {
                    color: "#3CDDCF",
                  },
                  a: {
                    color: "#fff",
                    align: "center",
                    fontSize: "14",
                  },
                  // b: {
                  //   width: 1,
                  //   height: 15,
                  //   borderWidth: 1,
                  //   borderColor: "#234e6c",
                  //   align: "left",
                  // },
                },
              },
            },
          },
          {
            type: "bar",
            name: "目标就业",
            data: [8000, 2000, 500, 500, 1000],
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(224,98,174,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(224,98,174,0.4)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [10, 10, 10, 10],
                shadowColor: "rgba(224,98,174,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                width: 80,
                height: 20,
                backgroundColor: "rgba(224,98,174,0.3)",
                borderRadius: 200,
                position: ["-14", "-40"],
                distance: 1,
                formatter: ["    {d|●}", " {a|{c}}     \n", "    {b|}"].join(
                  ","
                ),
                rich: {
                  d: {
                    color: "#E062AE",
                  },
                  a: {
                    color: "#E062AE",
                    align: "center",
                    fontSize: "14",
                  },
                  // b: {
                  //   width: 1,
                  //   height: 15,
                  //   borderWidth: 1,
                  //   borderColor: "#234e6c",
                  //   align: "left",
                  // },
                },
              },
            },
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" class>
$box-height: 520px;
$box-width: 100%;
#bottomRight {
  height: $box-height;
  width: $box-width;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.content {
  padding: 15px;
}
.bg-color-black {
  height: $box-height - 30px;
  border-radius: 10px;

  text-align: center;
}
.text {
  color: #fff;
  text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
    0 0 40px #38cefe;
}

.year_zhaop {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, 50%);

  .year_data {
    color: #3de7c9;
    font-size: 26px;
    margin-top: 20px;
  }
  .zfeed {
    margin-top: -40px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.colorBlue {
  font-size: 14px;
}
.unit {
  color: #fff;
  font-size: 14px;
}
</style>
